import React from 'react';

export default class NotFoundPage extends React.Component {
  render() {
    return (
      <div className="flex flex-col items-center">
        <div className="flex flex-col gap-20 pb-32 pt-12 px-6 w-full max-w-screen-md">
          <p className="year-content">
            De pagina die je probeert te bezoeken bestaat niet.
          </p>
        </div>
      </div>
    );
  }
}

export function Head() {
  return <title>Pagina niet gevonden - Stichting Justitia</title>;
}
